<template>
  <BaseDialog append-to-body :title="title" :dialogVisible.sync="dialog" width="80vw">
    <div class="select-warpper" @click="showCountryDialog">
      <span class="add mr10" v-if="countriesNames.length"
        >{{ isDomestic ? '已选省份/地区：' : '已选国家/地区：' }}
        <span>{{ countriesNames.join('、') }}</span>
      </span>
      <span class="add" v-else>{{ isDomestic ? '添加省份 / 地区' : '添加国家 / 地区：' }}</span>
    </div>
    <FedexGoodDetail ref="fedexGoodDetail" :resetData="resetData" />
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确 定</el-button>
    </span>
  </BaseDialog>
</template>

<script>
import { commonFromMixin } from '@/mixins'
import FedexGoodDetail from './fedexGoodDetail'
import cloneDeep from 'lodash/cloneDeep'
import { DOMESTIC_FORMWORK } from '@/utils/constant'
import { INTERNATIONAL_FORMWORK } from '@/utils/constant'

export default {
  mixins: [commonFromMixin],

  components: {
    FedexGoodDetail
  },

  props: {
    //复制时候重置data
    resetData: {
      type: Array,
      default: () => []
    },

    templateId: {
      required: true
    },

    //已经选择的国家
    selectedCountries: {
      type: Array,
      default: () => []
    },
    disabledList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    countriesNames({ selectedCountries }) {
      const idList = this.disabledList.map(({ value }) => value)
      const nameList = []
      selectedCountries.map((item) => {
        if (!idList.includes(item.value)) nameList.push(item.label)
      })
      return nameList
    },
    isDomestic() {
      return this.$route.query.category == DOMESTIC_FORMWORK
    }
  },
  // async mounted() {
  //   if (this.isDomestic) {
  //     const data = {
  //       level: 1,
  //       page: { pageSize: 0, pageIndex: 0 }
  //     }
  //     const { code, detail } = await getProvinceList(data)
  //     if (!$SUC(code)) {
  //       this.provinceList = detail
  //     }
  //   }
  // },
  methods: {
    showCountryDialog() {
      this.$emit('showCountryDialog', this.selectedCountries)
    },

    formatDataByCountry(data) {
      const cloneData = cloneDeep(data)
      const tmpArr = []
      const provinceList = this.countriesNames.map((item) => item)
      this.selectedCountries.map((country) => {
        if (provinceList?.includes(country.label)) {
          const { id: countryId, label } = country
          cloneData.map((item) => {
            tmpArr.push({
              countryId,
              countryName: label,
              templateId: this.templateId,
              ...cloneDeep(item)
            })
          })
        }
      })
      return tmpArr
    },

    doSubmit() {
      const message = this.isDomestic ? '请先选择需要添加的省份' : '请先选择需要添加的国家'
      if (this.countriesNames.length == 0) return this.$message.warning(message)
      const { fedexGoodDetail } = this.$refs
      if (!fedexGoodDetail) return
      const { data } = fedexGoodDetail
      const formatData = this.formatDataByCountry(data)
      console.log(11111111, data)
      //更新列表数据
      this.$emit('pushData', formatData)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.select-warpper {
  display: inline-block;
  margin-top: 14px;
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
  .add {
    cursor: pointer;
    color: #3841db;
  }
}
</style>
